.values__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.value__bool {
  width: 70px;
  min-height: 20px;
  font-size: 12px;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 20px;
}

.value__off {
  background-color: red;
}

.value__on {
  background-color: green;
}

.value__input {
  font-size: 12px;
}

.values__container input[type="number"],
.values__container input[type="text"] {
  width: 120px;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  outline: none;
}

.values__container input[type="text"] {
  text-align: left;
}

.values__container input[type="number"]:focus,
.values__container input[type="text"]:focus {
  border: 2px red dotted;
  border-radius: 4px;
}

.values__container {
  cursor: pointer;
  padding-left: 5px;
  /* display: none; */
}

input[type="number"]:focus + .value__button,
input[type="text"]:focus + .value__button {
  /* display: flex; */
}

.cell_justified {
  font-size: small;
  text-align: justify;
}

.values__container input[type="color"] {
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  outline: none;
}

.values__container input[type="range"] {
  width: 80%;
  height: 5px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.values__container input[type="range"]::-webkit-slider-thumb {
  width: 12.5px;
  height: 12.5px;
  background: #04aa6d;
  cursor: pointer;
}

.values__container input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  margin: 0;
}

.rgb__container {
  font-size: 11px;
  margin-left: 5px;
  margin-top: 5px;
}

.rgb__color {
  margin: 0px 5px;
  padding: 2px 4px;
  border-radius: 8px;
  font-weight: 500;
}

.rgb__brightness__container {
  font-size: 10px;
  margin-top: 5px;
}
