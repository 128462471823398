body {
  background-color: #cecece;
}

h1 {
  margin-top: 10px;
  margin-left: 10px;
}

.center__box {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center__box__component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no__data {
  min-height: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

a,
.link {
  color: black;
  text-decoration: none;
}

#sidenav {
  display: flex;
  height: "100vh";
}

.date {
  font-size: 14px;
  font-style: italic;
  margin-top: -8px;
}

.icon__action {
  padding: 2px;
}

.header__action {
  display: flex;
  flex-direction: row;
  margin: 10px 15px 0px 15px;
  align-items: center;
  justify-content: space-between;
}

.buttons__action {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.border__eff {
  border: 1px solid black;
  border-radius: 10px;
}

.border__eff:hover {
  border: 2px solid black;
  background-color: #816d73;
  font-weight: bold;
}

.icons__container {
  display: flex;
  justify-content: center;
}

.icons {
  display: flex;
  justify-content: center;
  width: 30px;
  margin: 0px 2.5px;
}

.icons:hover .icons__out {
  display: none;
}

.icons .icons__fill {
  display: none;
}

.icons:hover .icons__fill {
  display: flex;
  align-items: center;
}

.cursor__pointer {
  cursor: pointer;
}

.cursor__move {
  cursor: move;
}

/*a :hover,
.link:hover {
  color: #096247;
}*/

/*.link-label {
  color: blue;
  text-decoration: none;
}*/

/* 
.link-label:hover {
  color: #096247;
  font-weight: bold;
}



.card-md {
  height: 400px;
}

.card-lg {
  min-height: 200px;
  max-height: 550px;
}

.card-lg-chart {
  height: 600px;
}

.mg5 {
  margin: 5px;
}

.mg10 {
  margin: 10px;
}

.pd10 {
  padding: 5px;
}

.pd5 {
  padding: 5px;
}

.container-align-center {
  text-align: center;
  justify-content: center;
}

.change-icon-container {
  display: block;
}

.change-icon-in {
  align-self: center;
}

.change-icon-container:hover .change-icon-in {
  display: none;
}

.change-icon-container:hover .change-icon-out {
  display: block;
}

.change-icon-container .change-icon-out {
  display: none;
}

.container-right {
  display: flex;
  justify-content: end;
  margin-right: 5px;
} */
