.view__container {
  margin: 15px;
  border: 1px solid black;
  border-radius: 7.5px;
}

.dash__item {
  padding: 5px 17px;
}

.small__text {
  font-size: 12px;
}
