.avatar__div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.5rem;
}

.avatar__pic__full {
  width: 150px;
  height: 150px;
  border: 2px black solid;
  border-radius: 50%;
  background-color: black;
  object-fit: contain;
}

.avatar__pic__half {
  width: 50px;
  height: 50px;
  border: 2px red solid;
  border-radius: 20%;
  background-color: black;
  object-fit: contain;
}

.profile__name {
  text-align: center;
  font-size: 1rem;
  margin-top: 0.1rem;
  font-weight: 500;
}

.profile__owner {
  text-align: center;
  font-size: 0.9rem;
  margin-top: -0.3rem;
  font-weight: 500;
  color: red;
}
